body {
    font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.secondaryButton {
    border-radius: 5px;
    margin-left: 10px;
    transition: background-color .25s;
}

.secondaryButton:hover {
    background-color: rgba(0,0,0,.25);
}

.deck {
    position: absolute;
    /*background-color: #ffeeff;*/
    width: 100vw;
    height: 300px;
    bottom: -250px;
    z-index: 2;
    transition: bottom 1s, opacity 1s, background-color 1s;
    /*border-radius: 5px 5px 0 0;*/
    animation: deck_swoosh 10s infinite ease-in-out;
}

@keyframes deck_swoosh {
    0% {
        transform: translateY(0);
        background-color: transparent;
    }

    5% {
        transform: translateY(-30px);
        background-color: rgba(0,0,0,0.25);
    }

    7% {
        transform: translateY(0);
        background-color: transparent;
    }
}

.inactive_deck {
    bottom: -275px;
    pointer-events: none;
    opacity: .5;
    -webkit-animation: unset !important;
    -o-animation: unset !important;
    animation: unset !important;
}

input {
    border: none;
}

input:focus {
    outline:none;
}

.deck:hover, .deck:focus, .deck:active {
    bottom: -50px;
    background-color: rgba(0,0,0,0.5);
    -webkit-animation: unset !important;
    -o-animation: unset !important;
    animation: unset !important;
}

.card_background_row {
    /*-webkit-animation: card_background_animation 60s linear infinite;*/
    /*-o-animation: card_background_animation 60s linear infinite;*/
    /*animation: card_background_animation 60s linear infinite;*/
    transform: translateY(-346px);
    filter: blur(10px);
}

.card_background_row_reverse {
    /*-webkit-animation: card_background_animation 60s linear reverse infinite;*/
    /*-o-animation: card_background_animation 60s linear reverse infinite;*/
    /*animation: card_background_animation 60s linear reverse infinite;*/
    transform: translateY(-173px);
    filter: blur(10px);
}

@keyframes card_background_animation {
    0% {transform: translateY(0px);}
    0% {transform: translateY(-692px);}
}

.player_scoreboard {
    background-color: #fff;
    justify-content: center;
    flex-grow: 0;
    flex-direction: row;
    max-height: 90px;
    display: flex;
}

.player_scoreboard_button {
    /*margin-top: 5px;*/
    padding: 5px 5px 5px 10px;
    /*text-align: center;*/
    font-weight: bold;
    /*background-color: #D911CB;*/
    border-top: #AB0563 1px solid;
    color: #000;
    flex-grow: 0;
    cursor: pointer;
    display: none;
    text-align: left;
}

.player_scoreboard_button:hover {
    background-color: lightgray;
}

@media screen and (min-width: 480px) {
    .player_scoreboard {
        position: absolute;
        height: 400px;
        left: 0;
        top: calc(50% - 200px);
        z-index: 1;
        max-width: 90px;
        max-height: unset !important;
        border-radius: 0 5px 5px 0;
        flex-direction: column !important;
        overflow: hidden;
    }

    .player_scoreboard_button {
        display: block !important;
    }
}

.speech_bubble {
    position: absolute;
    left: 80px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 3px 5px black;
}

.winner_wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    z-index: 3;
    transition: opacity 500ms;
}

.winner_box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-direction: row;
    max-width: 80vw;
}

.deck_card {
    cursor: pointer;
}

.lobby-settings-wrapper {
    display: grid;
    grid-template-columns: 1fr 400px;
    /*padding: 20px;*/
    gap: 10px;
}

@media only screen and (max-width: 600px) {
    .lobby-settings-wrapper {
        grid-template-columns: 1fr !important;
    }
}

.editable-strip {
    display: flex;
    flex-direction: row;
}

.delete-button {
    overflow: hidden;
    width: 0;
    cursor: pointer;
    transition: width .25s;
}

.editable-strip > *:focus {
    border: none;
    outline: none;
}

.editable-strip:hover > .delete-button, .editable-strip:focus > .delete-button {
    width: 1.5em;
}